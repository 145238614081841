body {
  margin: 0;
  padding: 0;
}

.ant-layout {
  background-image: url(./layout-bg.png);
}

::-webkit-scrollbar-track {
  background-color: #f5f5f5;
  border-radius: 3px;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: #f5f5f5;
  border-radius: 3px;
}

::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: rgb(186, 0, 0);
}

div {
  user-select: none;
}